#app {
    height: 100%;
}
#app_child {
    // min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
}

input.invalid, textarea.invalid{border-color: #dc3545 !important;}
.notification-message.is-display-block{display:block;}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
}
.processing .ring_loader{display:block;}
.form-error{color: #FF0000;}
.form-control.error{border-color: #FF0000;}
.modal_change_form .form-group.picker_group label{top:22px;}
.sortable-header{cursor:pointer;}


.page_nav .navigation li a.prev.disabled, .page_nav .navigation li a.next.disabled {
    cursor:default;
    opacity: .2;
}

.page_nav .navigation li a.prev.disabled:hover span, .page_nav .navigation li a.next.disabled:hover span {
    color: #999999;
}
.popover_info.error.show{display:block;}
.grecaptcha-badge{display:none;}

.expenses-wrapper.flex-column{flex-direction: column;}

.dash__board__container .select2-selection__rendered .select2-selection__choice {
    display:none;
}

.error~.invalid-feedback, .form_error~.invalid-feedback{display:block;}

.price_popover_item.active{display:block;}

.table-accordion__content.hidden, .dashboard-table .hidden{display:none !important;}

.info-list__input.info-list__input_combo .invalid-feedback{margin:0;padding:0;}

@media (min-width: 861px){
    .redeems-table td.inner-text-block .text_block.price-block label input {
        height: 2.25rem;
    }
}

// .select2-container--basic{z-index:1100;}
